@import '../../custom.scss';

.SigninWrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(../../assets/images/games/signinBackground.jpg);
    background-repeat: no-repeat;
    background-size: 100%;

    .SigninContainer {
        position: relative;
        background-color: white;
        width: 40%;
        min-height: 50vh;
        box-shadow: rgba(89, 87, 87, 0.35) 0px 5px 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        @media screen and (max-width:900px) {
            width: 55%;
        }

        @media screen and (max-width:600px) {
            width: 80%;
        }

        .SigninArrowBack {
            position: absolute;
            top: 30px;
            margin-left: 24px;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
        }

        .SigninContainerChildOne {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h1 {
                margin-bottom: 16px;
                color: $primary;
            }
        }
    }
}

form {
    width: 100% !important;
}

.signInMessage {
    color: red;
}

.input {
    width: 100% !important;
}

.signInSubmitButton {
    padding: 16px !important;
    width: 100%;
    border-radius: 0px !important;
    background-color: $primary !important;
    border: none !important;
}

@media (max-width:700px) {
    .SigninContainer {
        width: 85%;
    }

    form {
        width: 90% !important;
    }
}

@media (max-width:500px) {
    .SigninContainer {
        width: 95%;
    }

    form {
        width: 90% !important;
    }
}


@media (max-width:1250px) {
    .SigninArrowBack {
        top: 25px;
    }
}
@import '../../custom.scss';

.mainLogo {
    width: 145px;
    height: 48px;
}

.navbarWrapper {
    padding: 16px 0px;
    background-color: $primary;
    height: 10vh;

    .navLinks {
        color: $white !important;
        padding-left: 16px !important;
        padding-right: 16px !important;
    }

    .signinButton {
        border-radius: 0px;
        background-color: $third;
        border: none;
        margin: 0px 8px;
        text-transform: capitalize;
    }

    .signupButton {
        border-radius: 0px;
        background-color: transparent;
        border: 2px solid $secondary;
        margin: 0px 8px;
        text-transform: capitalize;

        @media screen and (max-width:992px) {
            margin: 8px;
        }
    }
}

.dropdown-toggle {
    margin-top: 8px;
    background-color: $primary !important;
    border: none !important;
    // border-radius: 100px;
}

.dropdown-toggle::after {
    display: none !important;
}

.navbar-light .navbar-toggler-icon {
    background-image: url(../../assets/images/menu/menu.png);
}

.navbar-light .navbar-toggler {
    border: 1px solid $secondary;
}

.navbar-collapse {
    background-color: $primary;
    z-index: 999 !important;
}
@import '../../custom.scss';

.footerWrapper {
    margin-top: 60px;
    margin-bottom: 0;
    height: auto;
    background-color: $primary;
    padding: 32px 0px;
}

.socialMediaContainer {
    display: flex;
    flex-direction: column;
    padding: 0px 32px;

    .socialMediaLink {
        text-decoration: none;

        div {
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            flex-direction: row;


            p {
                margin: 0;
                color: white;
                margin-left: 8px;
            }
        }
    }


}

.FooterCopyRightContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

@media (max-width:600px) {
    .help {
        margin-top: 16px;
    }

    .legal {
        margin-top: 16px;
        padding: 0px 32px;

    }
}
@import '../../custom.scss';

.categoriesWrapper {
    width: 100%;
    display: flex;
    overflow-x: scroll;

    @media screen and (min-width: 1650px) {
        align-items: center;
        justify-content: center;
    }

    .categoryContainer {
        cursor: pointer;
        flex: 0 0 150px;
        padding: 8px;
        margin: 0px 16px;
        background-color: $secondary2;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.categoriesWrapper::-webkit-scrollbar {
    display: none;
}

.categoryContainerSelected {
    background-color: $secondary !important;
}
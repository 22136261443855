@import '../../custom.scss';

.signupButton2 {
    border-radius: 0px;
    background-color: $primary !important;
    border: 2px solid $secondary !important;
    text-transform: capitalize !important;
}

.modal-content {
    background-color: rgba($secondary, 1);
}

.modal-header,
.modal-body {
    border-bottom: 1px solid $primary !important;
    border-radius: 0px !important;
}

.modal-footer {
    border-top: none !important;
    border-radius: 0px !important;
}

.welcomeImageContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalFooterContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .modalParagraph {
        color: $primary !important;
        text-align: center;
        font-weight: bolder;
        font-size: larger;
    }

    .modalParagraph2 {
        color: $red !important;
        text-align: center;
        font-weight: bolder;
        font-size: larger;
        padding: 16px 0px;
        width: 100%;
        background-color: rgba($red, 0.3);
    }
}

.modalTitle {
    width: 100%;
    color: $primary !important;
    font-weight: bolder;
    text-align: center !important;
}
@import '../../custom.scss';

.titleContainer {
    padding: 32px 16px 0px 16px;

    .title {
        padding-bottom: 4px;
        margin: 0;
        font-size: 20px;
        border-bottom: 1px solid $primary;
    }

    .parentTitle {
        padding-bottom: 4px;
        margin: 0;
        font-size: 24px;
        font-weight: bold;
        border-bottom: 1px solid $primary;
        text-align: center;
    }
}
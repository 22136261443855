@import '../../custom.scss';

.mySwiper {
    padding: 24px 0px;
    width: 100%;
    height: 75vh;

    @media screen and (min-width:1550px) {
        height: 60vh;
    }

    .promotionImageContainer {
        width: 100%;
        height: 100%;
        position: relative;

        .promotionImage {
            width: 100%;
            height: 100%;
            display: block;
        }

        .promotionImageOverlay {
            position: absolute;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.7);
            width: 100%;
            height: 100%;
            transition: .5s ease;
            opacity: 0;
            color: white;
            font-size: 20px;
            padding: 20px;
            text-align: center;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            .promotionImageButton {
                background-color: $secondary;
                border: 2px solid $secondary;
                border-radius: 0px;
            }
        }
    }
}

.swiper-slide {
    height: 200px !important;
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.promotionImageContainer:hover .promotionImageOverlay {
    opacity: 1;
}
@import '../../custom.scss';

.gamesWrapper {
    padding: 32px 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    .gameItemContainer {
        border-bottom: 2px solid $primary;
        // background-color: $third;
        margin: 8px;
        cursor: pointer;
        width: 200px;
    }
}

.gameItem {
    width: 100% !important;
    margin-bottom: 8px;
    text-align: center;

    .gameItemImageContainer {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;

        .gameItemImage {
            width: 100%;
            height: 100%;
            display: block;
            // margin-top: 16px;
            transition: transform .5s ease;
        }

        .gameItemOverlay {
            position: absolute;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.8);
            width: 100%;
            height: 100%;
            transition: .5s ease;
            opacity: 0;
            color: white;
            font-size: 20px;
            padding: 20px;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .gameItemPlayButton {
                background-color: $third;
                font-size: 18px;
                width: 120px;
                border: none;
                border-radius: 0px;
                margin-bottom: 8px;
                color: $black;
            }

            .gameItemDemoButton {
                background-color: transparent;
                font-size: 18px;
                width: 120px;
                border: 1px solid $white;
                border-radius: 0px;
                margin-bottom: 8px;
            }
        }
    }

    .gameItemName {
        margin: 0;
    }

}

.gameItemImageContainer:hover .gameItemImage {
    transform: scale(1.5);
}

.gameItemImageContainer:hover .gameItemOverlay {
    opacity: 1;
}
@import '../../custom.scss';

.SignupWrapper {
    padding-top: 48px;
    padding-bottom: 16px;
    padding-right: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(../../assets/images/games/modalImage.jpg);
    background-repeat: no-repeat;
    background-size: 100%;


    .SignupContainer {
        position: relative;
        background-color: white;
        width: 50%;
        min-height: 70vh;
        box-shadow: rgba(89, 87, 87, 0.35) 0px 5px 15px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        @media screen and (max-width:1000px) {
            width: 75%;
        }

        @media screen and (max-width:650px) {
            width: 90%;
        }

        .SignupArrowBack {
            position: absolute;
            top: 15%;
            margin-left: 24px;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
        }

        .SignupContainerChildOne {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h1 {
                margin-bottom: 16px;
                color: $primary;
            }
        }
    }
}

.signUpMarquee {
    margin: 16px 0px;
    padding: 4px 0px;
    font-weight: bold;
    color: $secondary;
    font-size: 18px;
}

form {
    width: 90% !important;
}

@media (max-width:700px) {
    .SignupContainer {
        width: 85%;
    }

    form {
        width: 90% !important;
    }
}

@media (max-width:500px) {
    .SignupContainer {
        width: 95%;
    }

    form {
        width: 90% !important;
    }
}

.submitButton {
    padding: 16px !important;
    width: 100%;
    border-radius: 0px !important;
    background-color: $primary !important;
    border: none !important;
}


.birthdateContainer {
    display: flex;
    flex-direction: column;
}

.date {
    background-color: none;
    font-size: 16px;
    width: 100%;
    height: 38px;
    border: 1px solid #b9b9b9;
    border-radius: 0px;
    padding-left: 8px;
    color: #7e7b8b;
    cursor: pointer;
}

.input {
    border-radius: 0px;
}

.phoneNumberInput {
    border: 1px solid #c4c4c4;
    padding: 4px;
}

.PhoneInputInput {
    border: none !important;
}

@media (max-width:1250px) {
    .SignupArrowBack {
        top: 25px;
    }
}
@import '../../custom.scss';

.reviewsWrapper {
    padding: 16px 0px;

    .allReviewsContainer {
        overflow-x: scroll;
        height: auto;
        display: flex;
        padding: 10px 0px;

        .reviewContainer {
            width: 250px;
            height: 300px;
            padding: 16px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            margin-right: 8px;

            .reviewHeader {
                display: flex;
                align-items: center;

                .reviewHeaderAvatar {
                    width: 70px;
                    height: 70px;

                }

                .reviewHeaderName {
                    color: $primary;
                    margin: 8px;
                }
            }

            .reviewBody {
                padding: 24px 0px;
                display: flex;
                flex-direction: column;

                .reviewBodyDescription {
                    font-size: 18px;
                    padding: 8px 0px;
                }
            }
        }
    }
}